import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getMeuAgente() {
      return new Promise((resolve, reject) => {
        axios.get('meu-agente')
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getMeuAgenteSobre(context, id) {
      return new Promise((resolve, reject) => {
        axios.get(`meu-agente/${id}/sobre`)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getMeuAgenteOperacao(context, id) {
      return new Promise((resolve, reject) => {
        axios.get(`meu-agente/${id}/operacao`)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    setMeuAgentePerfilConfig(context, data) {
      return new Promise((resolve, reject) => {
        axios.put('meu-agente/operacao', data)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    assinarAgente(context, data) {
      return new Promise((resolve, reject) => {
        axios.put('meu-agente/assinar', data)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

  },
}
